import Marquee from "react-fast-marquee";
import { ImLocation } from 'react-icons/im';
import { MdCall } from 'react-icons/md';
import { Container, Row, Col } from "react-bootstrap";
import './footer.css';
import { NavLink } from "react-router-dom";
import Logo from '../../Images/OSPBlueLogo.png';


export default function Footer() {
    return (
        <div className="footer-main" >
            <Marquee className="home-marquee">
                <div className="home-marq-p1 d-flex">ONE <p className="home-marq-p2">SOLUTION</p> POINT</div>
            </Marquee>

            <Container >
                <Row>
                    <Col lg={4}>
                        <NavLink to='/' className="footer-logo">
                            <img src={Logo} alt="onesolutionpoint" style={{ height: '6rem', width: '16rem' }} />
                        </NavLink>
                    </Col>

                    <Col lg={4}>
                        <div className="footer-social">
                            <h3>FOLLOW US ON</h3>

                            <div className="" >
                                <ul className="social-icons">
                                    <li><a href="https://www.facebook.com/1SOLUTIONPOINTS?mibextid=ZbWKwL"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/onesolution41"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/menhaj-akhtar-96249916a/?trk=contact-info"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/onesolutionpoint41/"><i className="fa fa-instagram"></i></a></li>
                                </ul>
                            </div>

                        </div>
                    </Col>

                    <Col lg={4}>
                        <div className="footer-addr">
                            <div className="f-addr">
                                <div>
                                    <ImLocation className="footer-icon-address" />
                                </div>

                                <NavLink to='/contact'>
                                    <p>2c, h/11, Chatu Babu Lane, Melaranoor, Entally, Kolkata, West Bengal 700014</p>
                                </NavLink>
                            </div>

                            <NavLink to='https://api.whatsapp.com/send?phone=917003907837.' className="f-addr">
                                <MdCall className="footer-icon-address" />
                                <p>+91-7003-907-837</p>
                            </NavLink>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}