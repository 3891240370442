// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import './homeswiper.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function HomeSwiper() {
    return (
        <Swiper className='my-3'
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={5}
            slidesPerView={3}
            navigation
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        >
            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/4185141/galshir-cactus-coffee.png' alt='' />
            </SwiperSlide>

            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/4473482/galshir-dog-walker.gif' alt='' />
            </SwiperSlide>
            
            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/5743606/gal-shir-scooter_2x.png' alt='' />
            </SwiperSlide>

            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/4738104/galshir-storytime.png' alt='' />
            </SwiperSlide>

            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/6146136/galshir-tea-biscuit_2x.png' alt='' />
            </SwiperSlide>

            <SwiperSlide >
                <img className='homeslide' src='https://cdn.dribbble.com/users/729829/screenshots/4185141/galshir-cactus-coffee.png' alt='' />
            </SwiperSlide>

        </Swiper>
    );
};