import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";
import MyNavbar from "./Navbar/Navbar";
import About from "./About/About";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Animation from "./Component/Animation/Animation";
import FloatingButton from "./Component/FloatingButton/FloatingButton";


export default function App() {
  return (
    <BrowserRouter>
    <Animation/>
      <Routes>
        <Route path="/" element={<MyNavbar />}>
          <Route index element={<Home />} />
          <Route path='/about' element={<About/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/contact' element={<Contact/>}/>
        </Route>
      </Routes>
      <FloatingButton/>
    </BrowserRouter>
  );
}


