

import React, { useState } from 'react';
import './mycarousel.css'; // Import your CSS file

const MyCarousel = () => {
  const [angle, setAngle] = useState(0);

  const gallerySpin = (sign) => {
    setAngle((prevAngle) => (sign ? prevAngle - 90 : prevAngle + 90));
  };

  return (
    <>
      <div id="carousel">
        <figure id="spinner" style={{ transform: `rotateY(${angle}deg)` }}>
          <img src="https://vegaawards.com/images/vega-logo.png" alt="" />
          <img src="https://www.horizoninteractiveawards.com/assets/tools/statue.png" alt="" />
          <img src="https://findvectorlogo.com/wp-content/uploads/2020/01/hermes-creative-awards-vector-logo.png" alt="" />
          <img src="https://exchange4media.gumlet.io/news-photo/1536749753_cFtguf_Mashable-logo.jpg?w=400&dpr=2.6" alt="" />
          {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgqFOjjyAcYHzOBQkfZpITM5yn4bX6eDnQw&usqp=CAU" alt="" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgqFOjjyAcYHzOBQkfZpITM5yn4bX6eDnQw&usqp=CAU" alt="" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgqFOjjyAcYHzOBQkfZpITM5yn4bX6eDnQw&usqp=CAU" alt="" />
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgqFOjjyAcYHzOBQkfZpITM5yn4bX6eDnQw&usqp=CAU" alt="" /> */}
        </figure>
      </div>
      <span style={{ float: 'left' }} className="ss-icon" onClick={() => gallerySpin('-')}>
        &lt;
      </span>
      <span style={{ float: 'right' }} className="ss-icon" onClick={() => gallerySpin('')}>
        &gt;
      </span>
    </>
  );
};

export default MyCarousel;
