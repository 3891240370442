import { Col, Row, Container } from "react-bootstrap"
import './servicebox.css';
import { NavLink } from "react-router-dom";


export default function ServiceBox(props) {

    const { src, text1, text2, text3, text4 } = props;

    return (
        <>
            <Container>
                <Row>
                    <Col lg style={{ padding: '1rem' }}>
                        <div className="ser-div1" >
                            <img className="ser-col3-img" src={src} alt="build website">
                            </img>
                        </div>
                    </Col>
                    <Col lg style={{ padding: '1rem' }}>
                        <div className="ser-div2">
                            <h3 className="ser-div2-h31 d-flex">{text1}
                                <h3 className="ser-div2-h32">{text2}</h3>
                                {text3}
                            </h3>

                            <p>{text4}</p>
                            <NavLink to='/contact'>
                                <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <button className="plusbtnser"></button>
                                </div>
                            </NavLink>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}