

export default function GoogleMap() {
    return (
        <>
            <iframe width="100%" height="400"
                frameBorder="2px" scrolling="no" 
                title="Map for precise location"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.619173907237!2d88.37053217443282!3d22.555934233582583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277513dc7144b%3A0x3d9f6d48685e8cae!2sOne%20Solution%20Point!5e0!3m2!1sen!2sin!4v1701151553860!5m2!1sen!2sin"
            />
        </>
    )
}

