import React from 'react';

const MarqeeBrand = () => {
    return (
        <div id="shopify-section-1610664316b5f3985e" className="shopify-section custom-html">

            <div>
                <style>
                    {`
            @keyframes slide-brands {
              0% {
                transform: translateX(0);
              }

              50% {
                transform: translateX(calc(-14763px + 100vw));
              }

              100% {
                transform: translateX(0);
              }
            }

            .slider-brands-wrapper {
              width: 100%;
              overflow: hidden;
              background-color: #DFFF4F;
            }

            .slider-brands {
              width: 100%;
              display: flex;
              animation-name: slide-brands;
              animation-duration: 1000s;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }

            .slider-brands img {
              display: block;
              height: 20px;
              margin: 36px;
            }
          `}
                </style>
                <div className="slider-brands-wrapper">
                    <div className="slider-brands">
                        {/* Logos start here */}
                        {[...Array(5)].map((_, index) => (
                            <React.Fragment key={index}>
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Lifestyle-Logo-Light-25_2x_fc0e47c7-1d61-42c2-b430-253592ebed67.png?v=1692035845" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/AvMag-Logo-Retina-300x98.png?v=1692027715" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/WSFL_logo_2020_cba48286-5362-47b7-b2c8-6d1f811e1e35.png?v=1683147256" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Lifestyle-Logo-Light-25_2x_fc0e47c7-1d61-42c2-b430-253592ebed67.png?v=1692035845" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/AvMag-Logo-Retina-300x98.png?v=1692027715" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/WSFL_logo_2020_cba48286-5362-47b7-b2c8-6d1f811e1e35.png?v=1683147256" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/yahoo_logo.png?v=1682523543" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/CBS_logo.png?v=1682522888" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Jgame_SF305-Logo-black.png?v=1680282266" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/myp_logotipo-black.png?v=1680281867" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Jgame_WsvnMiami_bfd867a9-38e1-4c18-beaf-60a604a413ef.png?v=1678980196" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/decoDrive_ac32adf2-f258-4c07-9abd-73642d7c9019.png?v=1678980196" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/readers-digest.png?v=1658590247" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/press-glossy.png?v=1658839488" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/press-hamptons.png?v=1658839488" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/press-NY1.png?v=1658839488" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/press-refinery29.png?v=1658839488" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/press-tennis.png?v=1658839488" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Artboard_1_4x_39dc1836-8509-4dc1-93de-f2ba026dc67d.png?v=1659368758" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/Bustle_Logo.png?v=1669825446" alt="" />
                                <img src="https://cdn.shopify.com/s/files/1/0610/0615/1901/files/NBC_DFW_Black.png?v=1669826432" alt="" />

                            </React.Fragment>
                        ))}
                        {/* End Logos */}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default MarqeeBrand;
