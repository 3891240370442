import './contactform.css';

export default function ContactForm(props) {
    return (
        <div className="login">
            <form  className="login__form" action='https://formspree.io/f/mknllrqg' method='POST'>
                <h3 className="login__title">{props.text}</h3>

                <div className="login__inputs">
                    <div className="login__box">
                        <input
                            type="text"
                            name='fullname'
                            className="login__input"
                            placeholder="Full Name"
                            required
                            autoComplete='off'
                        />
                        <i className="ri-mail-fill"></i>
                    </div>

                    <div className="login__box">
                        <input
                            type="email"
                            name='email'
                            className="login__input"
                            placeholder="Email"
                            required
                            autoComplete='off'
                        />
                        <i className="ri-mail-fill"></i>
                    </div>

                    <div className="login__box">
                        <input
                            type="phone"
                            name='phone'
                            className="login__input"
                            placeholder="Phone"
                            required
                            autoComplete='off'
                        />
                        <i className="ri-lock-2-fill"></i>
                    </div>
                </div>
                <button className="plusbtn"></button>
            </form>
        </div>
    )
}


















