import { Container, Row, Col } from "react-bootstrap";
import './home.css';
import Laptop from "../Component/Laptop/Laptop";
import { PiWebhooksLogoDuotone } from "react-icons/pi";
import { GiGamepad } from "react-icons/gi";
import { RiAppsLine } from "react-icons/ri";
import Footer from "../Component/Footer/Footer";
import { NavLink } from "react-router-dom";
import { SiGooglebigquery, SiTaichigraphics } from "react-icons/si";
import { AiOutlineAreaChart } from "react-icons/ai";
import MarqeeBrand from "../Component/Marquee/MarqeeBrand";
import HomeSlider from "../Component/HomeSwiper/HomeSwiper";
import HomeImgSec from "../Component/HomeImgSec/HomeImgSec";
import ContactFrom from '../Component/Form/ContactForm';
// import Tablet from '../Component/Tablet/Tablet.';


const serviceData = [
    { Icon: PiWebhooksLogoDuotone, title: 'BUILD YOUR WEBSITE', description: 'Transform your business into a digital success story with a professionally crafted website — the key to unlocking new customers, boosting your online presence, and driving unprecedented growth.' },

    { Icon: GiGamepad, title: 'DEVELOP MOBILE GAMES', description: 'Unleash endless fun and profit with our captivating mobile games – play to win and turn your entertainment into earnings! #GameOn #EarnWhileYouPlay. your time is now!' },

    { Icon: RiAppsLine, title: 'BUILD YOUR APP', description: 'Elevate your business to new heights with our cutting-edge mobile application – seamlessly connecting you with customers, boosting efficiency, and unlocking growth opportunities.' },

    { Icon: SiGooglebigquery, title: 'SEARCH ENGINE OPTIMISATION', description: 'Unlock your website\'s full potential and dominate search engine rankings with our expert SEO services – your gateway to increased visibility, more traffic, and accelerated business growth!' },

    { Icon: SiTaichigraphics, title: 'GRAPHIC DESIGNING', description: 'Elevate your business presence with our expert graphic design services – where creativity meets impact. Boost engagement, leave a lasting impression, and stand out from the competition.' },

    { Icon: AiOutlineAreaChart, title: 'DIGITAL MARKETING', description: 'Transform your brand\'s online presence and skyrocket your success with our expert digital marketing services – driving results, capturing audiences, and maximizing your business potential!' },
];


export default function Home() {


    return (
        <>
            <Container fluid className="home-cont-main bg-info" >
                <Row>
                    <Col>

                        <Container fluid className="home-cont mt-3" >

                            <Row>
                                <Col>

                                    <Container>
                                        <Row >
                                            <Col lg={6} style={{ padding: '10px' }}>
                                                <div className="home-col2">
                                                    <div className="col2-div">
                                                        <h2 className="home-p1">A Digital Marketing Agency specializing in</h2>
                                                        <h2 className="home-p2"> Website Designs, Search Engine Optimisation, Gaming, Web-Apps.....</h2>
                                                        <div className="home-btn-div d-flex mb-2">
                                                            <NavLink to='/services'>
                                                                <button className="plus"></button>
                                                            </NavLink>
                                                            <p>We offer digital transformation and technological innovation.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6} style={{ padding: '10px' }}>
                                                <div className="home-col3">
                                                    <div className="col3-div">
                                                        <Laptop />
                                                        <h2 className="home-p3 mt-2">ONE SOLUTION POINT</h2>
                                                        <div className="home-btn-div d-flex">
                                                            <NavLink to='/contact'>
                                                                <button className="plus1"></button>
                                                            </NavLink>
                                                            <p>Connect with us today to get a free consultation from our experts</p>
                                                        </div>
                                                        <div className="" >
                                                            <ul class="social-icons">
                                                                <li><a href="https://www.facebook.com/1SOLUTIONPOINTS?mibextid=ZbWKwL"><i className="fa fa-facebook"></i></a></li>
                                                                <li><a href="https://twitter.com/onesolution41"><i className="fa fa-twitter"></i></a></li>
                                                                <li><a href="https://www.linkedin.com/in/menhaj-akhtar-96249916a/?trk=contact-info"><i className="fa fa-linkedin"></i></a></li>
                                                                <li><a href="https://www.instagram.com/onesolutionpoint41/"><i className="fa fa-instagram"></i></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <div className="home-fea-sec d-flex mt-5" >
                                                    <h3 className="home-fea-h31">FEATURED</h3>
                                                    <h3 className="home-fea-h32">SERVICES</h3>
                                                </div>

                                                <Container className="mt-3 mb-5">
                                                    <Row className="mt-5 d-flex" >
                                                        {serviceData.map((service, index) => (
                                                            <Col md={4} key={index} style={{ padding: '10px' }}>
                                                                <NavLink to='/services'>
                                                                    <div className="home-fea-col ">
                                                                        <div className="home-fea-icondiv">
                                                                            <service.Icon className="home-fea-icons" />
                                                                        </div>
                                                                        <div className="home-fea-div mt-4">
                                                                            <h3>{service.title}</h3>
                                                                            <p>{service.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            </Col>
                                                        ))}
                                                    </Row>


                                                    <Row>
                                                        <Col lg={12} className="mt-5">
                                                            <div className="home-div-para">
                                                                <p className="paraHead">Matching Ideas With Innovative And Strategic Action:</p>
                                                                <p className="para">One Solution Point is a mash of creativity, dedication and sustainability. Whether you want to boost organic website traffic, dominate social media, make your business become a brand or want to do anything digital with your business- we come up with ‘wow’ ideas and execute with innovative thinking and strategic action. <br />
                                                                    At One Solution Point, we are your premier choice for digital marketing in Kolkata. Our team of experts is dedicated to providing tailored solutions that elevate your online presence and drive real results. As a leading digital marketing company in Kolkata, we offer a comprehensive suite of services including SEO optimization, social media optimization services, PPC advertising, content marketing, and web design. With our strategic approach, we ensure your brand gets noticed in the digital landscape, reaching your target audience effectively. Let us transform your online presence into a powerful marketing asset that generates leads and boosts your business growth. Choose Kreative Machinez for excellence in digital marketing service in Kolkata.
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>

                                            </Col>
                                        </Row>
                                    </Container>

                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="my-5">
                                    <h3 className="brand-marq mb-4">OUR CLIENTS</h3>
                                    <MarqeeBrand />
                                    <HomeSlider />
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="mb-5 mt-1">
                                    <HomeImgSec />
                                </Col>
                            </Row>







                            <Row >
                                <Col lg className="my-5 bg-">
                                    <ContactFrom text="Let's connect"/>
                                </Col>

                                {/* <Col lg className=" my-5 bg-">
                                    <Tablet />
                                </Col> */}
                            </Row>










                            <Row style={{ backgroundColor: '#0e2f3a' }}>
                                <Col lg>
                                    <Footer />
                                </Col>
                            </Row>

                        </Container>

                    </Col>
                </Row>
            </Container>
        </>
    )
}