import './laptop.css';

export default function Laptop() {
    return (
        <>
            <div className="comp">
                <div className="monitor">
                    <div className="mid">
                        <div className="site">
                            <div className="topbar">
                                <div className="cerrar">
                                    <div className="circl"></div>
                                    <div className="circl"></div>
                                    <div className="circl"></div>
                                </div>
                            </div>
                            <div className="inhead">
                                <div className="mid">
                                    <div className="item"></div>
                                </div>
                                <div className="mid txr">
                                    <div className="item"></div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                    <div className="item"></div>
                                </div>
                            </div>
                            <div className="inslid">

                            </div>
                            <div className="incont">
                                <div className="item"></div>
                                <div className="item"></div>
                                <div className="item"></div>
                                <div className="item"></div>
                                <div className="wid">
                                    <div className="itwid">
                                        <div>
                                            <div className="contfoot"></div>
                                        </div>
                                    </div>
                                    <div className="itwid">
                                        <div>
                                            <div className="contfoot"></div>
                                        </div>
                                    </div>
                                    <div className="itwid">
                                        <div>
                                            <div className="contfoot"></div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="infoot">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mid codigo">
                        <div className="line">
                            <div className="item var"></div>
                            <div className="item cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line">
                            <div className="item min var"></div>
                            <div className="item min fun"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line">
                            <div className="item var"></div>
                            <div className="item atr"></div>
                            <div className="item cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item min atr"></div>
                            <div className="item lrg fun"></div>
                            <div className="item min fun"></div>
                            <div className="item lrg cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item lrg atr"></div>
                            <div className="item min fun"></div>
                            <div className="item min cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item atr"></div>
                            <div className="item min fun"></div>
                            <div className="item atr"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item min atr"></div>
                            <div className="item min cont"></div>
                            <div className="item lrg atr"></div>
                            <div className="item  fun"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item min atr"></div>
                            <div className="item lrg fun"></div>
                            <div className="item lrg cont"></div>
                            <div className="item min fun"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab2">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab2">
                            <div className="item min atr"></div>
                            <div className="item min fun"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab3">
                            <div className="item min atr"></div>
                            <div className="item min fun"></div>
                            <div className="item lrg fun"></div>
                            <div className="item lrg cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab3">
                            <div className="item min atr"></div>
                            <div className="item min fun"></div>
                            <div className="item lrg atr"></div>
                            <div className="item lrg cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab4">
                            <div className="item min fun"></div>
                            <div className="item lrg atr"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab1">
                            <div className="item atr"></div>
                            <div className="item var"></div>
                            <div className="item cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab3">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line tab4">
                            <div className="item min atr"></div>
                            <div className="item min fun"></div>
                            <div className="item lrg atr"></div>
                            <div className="item lrg cont"></div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="line">
                            <div className="item min var"></div>
                            <div className="clearfix"></div>
                        </div>

                    </div>
                </div>
                <div className="base">

                </div>
            </div>
        </>
    )
}