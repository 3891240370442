
import './floatingbutton.css';

export default function FloatingButton() {
    return (
        <>
            <a href="https://api.whatsapp.com/send?phone=917003907837." className="float" rel="noreferrer" target="_blank">
                <i className="fa fa-whatsapp my-float"></i>
            </a>
        </>
    )
}