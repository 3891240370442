import { Container, Row, Col } from "react-bootstrap";
import './about.css';
import Footer from "../Component/Footer/Footer";
import MyCarousel from "../Component/Carousel/MyCarousel";


export default function About() {
    return (
        <>
            <Container fluid className="about-container" >
                <Row>
                    <Col style={{padding:'0px'}}>

                        <Container fluid >
                            <Row>
                                <Col>

                                    <Container>
                                        <Row className="mb-5">
                                            <Col lg={12} className="about-col mb-5">
                                                <div className="about-div-main" >
                                                    <h3>ABOUT US</h3>
                                                    <p>In the ever-evolving landscape of the digital world, One Solution Point emerges as a steadfast beacon of innovation and expertise. Founded in 2017 and headquartered in the bustling city of Kolkata, we are a dynamic digital company with a mission to transform businesses through the power of online presence. We are proud to offer a comprehensive suite of services, encompassing website design and development, SEO, social media marketing, Facebook ads, and Google ads.</p>

                                                    <p>From our inception, we recognized the enormous potential of the digital realm in shaping the future of businesses. Over the years, we've diligently honed our skills and expanded our capabilities to become a one-stop destination for all your digital needs. Our journey is marked by a commitment to excellence, a hunger for innovation, and a relentless pursuit of client satisfaction.</p>

                                                    <p>While we call Kolkata our home, our services extend far beyond. One Solution Point is dedicated to serving clients across India. Whether you are in Mumbai, Delhi, Chennai, or any corner of the country, our team is ready to assist you in achieving digital excellence. Our commitment to excellence knows no geographical bounds.</p>
                                                </div>
                                            </Col>

                                            <Col lg={12} className="about-col">
                                                <div className="about-div-main">
                                                    <h3>AWARDS & CERTIFICATIONS</h3>
                                                    <Row>
                                                        <Col>
                                                            <MyCarousel />
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>

                                </Col>
                            </Row>

                            <Row style={{ backgroundColor: '#0e2f3a' }}>
                                <Col >
                                    <Footer />
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

