import { Container, Row, Col } from "react-bootstrap";
import './services.css';
import { MdOutlineFastfood, MdOutlineSchool } from "react-icons/md";
import { BiClinic, BiBuildingHouse, BiCameraMovie } from "react-icons/bi";
import { SiYourtraveldottv } from "react-icons/si";
import { GiClothes } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import Footer from "../Component/Footer/Footer";
import ServiceBox from "../Component/ServiceBox/ServiceBox";


export default function Services() {

    return (
        <>
            <Container fluid className="ser-main bg-info ">
                <Row>
                    <Col>

                        <Container fluid className="ser-cont ">
                            <Row>
                                <Col>

                                    <Container >
                                        <Row>
                                            <Col className="ser-col2">
                                                <h3 className="ser-h31">OUR SERVICES</h3>
                                            </Col>
                                        </Row>

                                        <Row className=" my-5" style={{ padding: '10px' }}>
                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row">
                                                    <ServiceBox src='https://ninjasites.com/images/blog/drag-and-drop-website-builders.png?v=1670571225013605944'
                                                        text1='BUILD'
                                                        text2='YOUR'
                                                        text3='WEBSITE'
                                                        text4='We understand that your website is your digital storefront. Our talented team of designers and developers crafts aesthetically pleasing, user-friendly, and technically sound websites that leave a lasting impression on your visitors. We ensure that your online presence not only reflects your brand identity but also converts visitors into customers.' />
                                                </div>
                                            </Col>

                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row" >
                                                    <ServiceBox src='https://img-c.udemycdn.com/course/750x422/197834_891d_6.jpg'
                                                        text1='DEVELOP'
                                                        text2='MOBILE'
                                                        text3='GAMES'
                                                        text4='Embark on an unparalleled gaming adventure with our handcrafted mobile games! Immerse yourself in a world where every tap unveils extraordinary graphics, thrilling gameplay, and boundless entertainment. Our designed games promise an immersive experience to your desires, offering a seamless blend of innovation and excitement.' />
                                                </div>
                                            </Col>

                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row" >
                                                    <ServiceBox src='https://helios-i.mashable.com/imagery/articles/036SM7saRgnSGmvT3XNLYXQ/hero-image.fill.size_1248x702.v1623372406.jpg'
                                                        text1='BUILD'
                                                        text2='YOUR'
                                                        text3='APP'
                                                        text4='With user-friendly interfaces and cutting-edge features, our apps are designed to enhance every facet of your mobile journey. Join a community of satisfied users who have embraced the future of mobile technology. Elevate your digital lifestyle — choose excellence, choose us. Embrace a new era of functionality, efficiency, and enjoyment. ' />
                                                </div>
                                            </Col>

                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row">
                                                    <ServiceBox src='https://atec.rs/wp-content/uploads/2019/04/Search_engine_optimization_SEO.jpg'
                                                        text1='SEARCH'
                                                        text2='ENGINE'
                                                        text3='OPTIMISATION'
                                                        text4="In the vast ocean of the internet, being found is crucial. We employ cutting-edge SEO strategies to enhance your website's visibility on search engines, driving organic traffic and increasing your online reach. With our SEO expertise, we help your business rise to the top of search results." />
                                                </div>
                                            </Col>

                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row">
                                                    <ServiceBox src='https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/169068628/original/f26951b920775ad615403ebe741cb98f3d910b22/do-any-type-of-graphic-design.jpg'
                                                        text1='GRAPHIC'
                                                        text2='DESIGNING'
                                                        text3=''
                                                        text4='Elevate your brand with our cutting-edge graphic design services! Transform your vision into captivating visuals that leave a lasting impression. Our expert designers craft stunning logos, engaging marketing materials, and eye-catching social media graphics tailored to your unique identity.' />
                                                </div>
                                            </Col>

                                            <Col lg={12} style={{ padding: '10px' }}>
                                                <div className="ser-row">
                                                    <ServiceBox src='https://www.adlibweb.com/wp-content/uploads/2022/07/digital-marketing.webp'
                                                        text1='DIGITAL'
                                                        text2='MARKETING'
                                                        text3=''
                                                        text4='Our social media experts create and execute compelling strategies to engage and expand your online community. We harness the full potential of platforms like Facebook, Instagram, Twitter, and LinkedIn to elevate your brand and drive results.' />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="ser-row2 my-3 mb-5">
                                            <h3 className="ser-rowh3-ind mt-5 mb-4">INDUSTRIES WE SERVE</h3>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <MdOutlineFastfood className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">HOSPITALITY</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <BiClinic className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">CLINICS</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <MdOutlineSchool className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">EDUCATION</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <SiYourtraveldottv className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3  mb-4">TOUR & TRAVEL</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <BiBuildingHouse className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">REAL ESTATE</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <BiCameraMovie className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">ENTERTAINMENT</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <GiClothes className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">FASHION</h3>
                                                </div>
                                            </Col>

                                            <Col md={3}>
                                                <div className="ser-col-ind mt-4">
                                                    <div className="ser-div-ind my-2 mt-4">
                                                        <AiOutlineCar className="ser-ind-icon" />
                                                    </div>
                                                    <h3 className="ser-div-h3 mb-4">AUTOMOBILE</h3>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Container>

                                </Col>
                            </Row>

                            <Row style={{ backgroundColor: '#0e2f3a' }}>
                                <Col lg>
                                    <Footer />
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

