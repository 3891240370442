import { Container, Row, Col } from "react-bootstrap";
// import Marquee from "react-fast-marquee";
import { Navbar, Nav } from "react-bootstrap";
import './navbar.css';
import { NavLink, Outlet } from "react-router-dom";
import Logo from '.././Images/OSPBlueLogo.png'

export default function MyNavbar() {
    return (
        <>
            <Container fluid className="navbar-main" >
                <Row>
                    <Col md={12} className="">
                        <Navbar collapseOnSelect expand="lg" style={{ background: 'transparent', overflow: 'auto' }}>
                            <Container>
                                <Navbar.Brand href="/">
                                    <img src={Logo} alt="onesolutionpoint" style={{ height: '4rem', width: '10rem' }} />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ border: 'solid lightBlue' }} />
                                <Navbar.Collapse id="responsive-navbar-nav" style={{ justifyContent: 'end' }}>
                                    <Nav className="ml-auto mt-2" style={{ alignItems: 'center', gap: '1rem' }}>
                                        <NavLink to="/" className="nav-buttons">
                                            <button className="btn rounded ">
                                                <span className="text-green">Home</span>
                                            </button>
                                        </NavLink>
                                        <NavLink to="/about" >
                                            <button className="btn rounded ">
                                                <span className="text-green">About</span>
                                            </button>
                                        </NavLink>
                                        <NavLink to="/services" >
                                            <button className="btn rounded ">
                                                <span className="text-green">Services</span>
                                            </button>
                                        </NavLink>

                                        <NavLink to="/contact " >
                                            <button className="btn rounded ">
                                                <span className="text-green">Contact</span>
                                            </button>
                                        </NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>

                </Row>
            </Container>
            <Outlet />
        </>
    )
}