import { Container, Row, Col } from "react-bootstrap";
import './contact.css';
import ContactForm from "../Component/Form/ContactForm";
import GoogleMap from "../Component/GMap/GMap";
import Footer from "../Component/Footer/Footer";


export default function Contact() {
    return (
        <>
            <Container fluid className="bg-info">
                <Row>
                    <Col>

                        <Container fluid className="cnt-cont">
                            <Row>
                                <Col className="mt-2">
                                    <ContactForm text="CONTACT US"/>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="my-5">
                                    <div className="gmap">
                                        <div className="mt-1">
                                            <GoogleMap />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row style={{ backgroundColor: '#0e2f3a' }}>
                                <Col lg>
                                    <Footer />
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                </Row>
            </Container>
        </>
    )
}