import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './homeimgsec.css';



const HomeImgSec = () => {
  return (
    <Container style={{ padding: '20px' }}>
      <Row className='hisrow'>
        <Col xs={12} md={6} className='hiscol1'>
          <Image className='hisImg'
            src="https://cdn.dribbble.com/users/730703/screenshots/6581243/avento.gif"
            alt="Sample Image"
            fluid
          />
        </Col>

        <Col xs={12} md={6} className='hiscol2'>
          <h1 className='hisHead'>We Help You Grow Sales by Improving your Website and Digital presence.</h1>
          <p className='hisPara'>
            As far as website design, development, and digital marketing are concerned, One Solution Point is the best pick. We turn every stone to stretch your business and market reach to prep your brand for the long run. We help businesses capture leads and build brand awareness by brushing up their online presence. We craft one-of-a-kind web designs blended with Google search (SEO), online ads, and content strategy for an uptick in your business.
          </p>
          <NavLink to='/contact'>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <button className="plusbtnser"></button>
            </div>
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeImgSec;
